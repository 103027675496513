import React from 'react'
import { graphql } from 'gatsby'

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slot from "../components/slot/slot"

import "../styles/style.scss"
import "../styles/main.scss"

import { SlotModel } from '../shared/models/slot.model';
import { CarouselModel } from '../shared/models/carousel.model';
import { SectionModel } from '../shared/models/section.model';
import { CallToActionModel } from '../shared/models/call-to-action.model';
import { VideoModel } from '../shared/models/video.model';
import { ImageModel } from '../shared/models/image.model';

import TypeSlot from '../shared/constants/type-slot';


class PropolisLotusPage extends React.Component {

  render() {
    var slotsList = this.props.data.allContentfulPage.nodes[0].slots;
    var page = this.props.data.allContentfulPage.edges[0].node;
    slotsList = slotsList.map(slot => {
      var slotObj = new SlotModel(slot);
      if (slotObj.content) {
        var contents = slotObj.content[0];
        switch (contents.__typename) {
          case TypeSlot.SlotCarousel:
            slotObj.content = new CarouselModel(contents)
            break;
          case TypeSlot.SlotSection:
            slotObj.content = new SectionModel(contents)
            break;
          case TypeSlot.SlotCTAProduct:
            slotObj.content = new CallToActionModel(contents)
            break;
          case TypeSlot.SlotVideo:
            slotObj.content = new VideoModel(contents)
            break;
          case TypeSlot.SlotImage:
            slotObj.content = new ImageModel(contents)
            break;
          case TypeSlot.SlotBrand:
            break;
          default:
            break;
        }
      }
      return slotObj;
    })
    return (
      <Layout bannerImage={page.headerImage} pageTitle={page.headerTitle}>
         <SEO title={page.title} description={page.description.description} pathname={page.slug} keywords={page.keywords} image={page.headerImage.file.url}/>
        {slotsList.map((slot, index) => {
         return <Slot data={slot.content} type={slot.content.typeName} backgroundClass={slot.backgroundClass} key={index}/>
        })}
      </Layout >
    )
  }
}

export default PropolisLotusPage

export const pageQuery = graphql`
  query PropolisLotusTemplateQuery($locale: String!) {
    allContentfulPage(filter: {slug: {eq: "propolis-lotus"}, node_locale: {eq: $locale}}) {
      totalCount
      edges {
        node {
          id
          node_locale
          title
          keywords
          headerTitle
          description {
            description
          }
          slug
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid {
              base64
              ...GatsbyContentfulFluid_withWebp
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
      nodes {
        slots {
          backgroundClass
          name
          contents {
            __typename
            ...on ContentfulSlotBlocksList{
              title
              description {
                internal {
                  content
                  description
                  ignoreType
                  mediaType
                }
              }
              topics {
                name
                
              }
              blocks {
                name
                backgroundClass
                body {
                  json
                }
              }
            }
            ... on ContentfulSlotCarousel {
              images {
                title
                colorInverted
                alignment
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
            ... on ContentfulSlotVideo {
              title
              videoFile {
                file {
                  url
                  fileName
                  contentType
                }
              }
            }
            ... on ContentfulSlotSection {
             __typename
             sectionHeading
            title
            sectionId
            subTitle
            imagePosition
            isPrimary
            underlineHeader
            body {
              json
            }
            image {
              title
              file {
                url
                fileName
                contentType
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            backgroundClass
            imageWidth
            bodyWidth
            linkUrl
          
            }
            ... on ContentfulSlotCallToAction {
              title
              subTitle
              content {
                name
                listItems {
                  linkDescription
                  linkUrl
                  image {
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
